
<template>
  <div class="pageContol UnitEdit">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">数据统计</a>
          <i>></i>
          <a href="javascript:;"  @click="$router.back()">单位统计</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">单位详情</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="framePage-scroll">
          <div class="ovy-a">
            <div class="form-box">
              <el-form
                ref="ruleForm"
                label-width="8rem"
                class="form"
                :rules="rules"
                :model="ruleForm"
              >
                <el-form-item label="单位名称:" prop="UnitName">
                    <p>{{ruleForm.UnitName}}</p>
                  <!-- <el-input v-model="ruleForm.UnitName" size="small" maxlength="50" show-word-limit></el-input> -->
                </el-form-item>
                <el-form-item label="上级单位：" prop="compParentName">
                    <p>{{ruleForm.compParentName || '无'}}</p>
                </el-form-item>
                <el-form-item label="单位类型：" prop="company">
                    <p>{{ruleForm.compTypeName || '无'}}</p>
                </el-form-item>
                <el-form-item label="社会信用代码：" prop="creditCode">
                    <p>{{ruleForm.creditCode || '无'}}</p>
                </el-form-item>
                <el-form-item label="所属行政区划：" prop="areaName">
                    <p>{{ruleForm.areaName || '无'}}</p>
                </el-form-item>
                <el-form-item label="所属行业：" prop="Industry">
                    <p>{{ruleForm.industryName || '无'}}</p>
                </el-form-item>
                <el-form-item label="联系人电话：" prop="contactphone">
                    <p>{{ruleForm.contactphone || '无'}}</p>
                </el-form-item>
                <el-form-item label="法人姓名：" prop="compLegal">
                    <p>{{ruleForm.compLegal || '无'}}</p>
                </el-form-item>
                <el-form-item label="法人身份证号：" prop="compIdcard">
                    <p>{{ruleForm.compIdcard || '无'}}</p>
                </el-form-item>
                <el-form-item label="电子邮箱：" prop="compEmail">
                    <p>{{ruleForm.compEmail || '无'}}</p>
                </el-form-item>
                <el-form-item label="经营范围：" prop="compBusiness">
                    <p>{{ruleForm.compBusiness || '无'}}</p>
                </el-form-item>
                <el-form-item label="地址：" prop="compAddress">
                    <p>{{ruleForm.compAddress || '无'}}</p>
                </el-form-item>
                <!-- 市场负责人 -->
                <!-- <el-form-item label="市场负责人" prop="marketLeader">
                  <el-select v-model="ruleForm.marketLeader" clearable filterable placeholder="请选择" size="small">
                    <el-option
                      size="small"
                      v-for="item in IndustryList"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    ></el-option>
                  </el-select>
                </el-form-item> -->
                <el-form-item label="营业执照：" prop="thumbnail">
                  <el-upload
                    :before-upload="$beforeAvatarUpload"
                    :http-request="$requestMine"
                    :show-file-list="false"
                    class="img-el-upload"
                    action
                    accept="image/png, image/gif, image/jpg, image/jpeg"
                    disabled="true"
                  >
                    <el-image
                      :src="ruleForm.thumbnail || require('@/assets/develop.png')"
                      fit="contain"
                      class="imgCenter"
                    ></el-image>
                  </el-upload>
                </el-form-item>
              </el-form>
            </div>
            <div class="btn-box flexcc">
              <el-button @click="doCancel" class="bgc-bv">返 回</el-button>
              <!-- <el-button class="bgc-bv" @click="doAddSave">确 定</el-button> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import UploadPic from "@/mixins/UploadPic";
import { mapGetters } from "vuex";
export default {
  name: "set/UnitDetails",
  components: {},
  mixins: [UploadPic],
  data() {
    return {
      stu: "add",
      // 基本信息
      ruleForm: {
        UnitName: "",
        compParentName:'',
        compTypeName:'',
        creditCode: "",
        areaName: "",
        industryName: "",
        contactphone: "",
        compLegal: "",
        compIdcard: "",
        compEmail: "",
        compBusiness: "",
        compAddress: "",
        // marketLeader:'', //市场负责人
        thumbnail: "",
        fileKey: "",
        parentCompId:'',
      },
      unitJson: {},
      // marketLeaderArr: [], //市场负责人下拉框数组对象
    
    };
  },
  created() {
    this.unitJson = this.$route.query.compId;
    this.getareatree();
    this.queryData(this.unitJson);
   
  },
  computed: {
    
  },
  mounted() {
   
  },
  methods: {
    getareatree() {
      this.$post("/sys/area/tree").then(ret => {
        this.areatreeList = ret.data;
      });
    },
    // 查看
    queryData(compId) {
      this.$post("/sys/company/info", { compId })
        .then(res => {
          const data = res.data || {};
          this.ruleForm = {
            UnitName: data.compName,
            compParentName: data.compParentName,
            compTypeName: data.compTypeName,
            creditCode: data.compNo,
            areaName: data.areaName,
            industryName: data.industryName,
            contactphone: data.compPhone,
            compLegal: data.compLegal,
            compIdcard: data.compIdcard,
            compEmail: data.compEmail,
            compBusiness: data.compBusiness,
            compAddress: data.compAddress,
            thumbnail: data.compLicense,
            parentCompId:data.parentCompId
          };
        //    this.getCompany(this.ruleForm.parentCompId);
        })
        .catch(err => {
          return;
        });
    },
    /* 返回 */
    doCancel() {
      this.$router.push({
        path: "/web/set/unitStatistics",
        query: {
          refresh: true
        }
      });
    }
  }
};
</script>
<style lang="less">
.UnitEdit {
  .el-form {
    width: 100%;
    display: flex;
    flex-direction: column;
    .el-form-item {
      width: 100%;
      margin-bottom: 22px;
    }
  }
}
</style>

